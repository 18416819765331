import React, { PureComponent } from 'react'
import Img from 'gatsby-image'
import ParallaxContainer from '../parallax-container'
import styles from './pagesection.module.css'


export default class PageSection extends PureComponent {
  render() {
    const data = this.props.data;

    return(
      <section 
        id={data.sectionId} 
        className={styles.pagesection + " " + (data.imageAsBackground ? styles.fullWidth : null) + " " + (data.mediaOrder ? styles.reversed : null) + " " + (data.mediaWidth == "100" ? styles.image100 : "") + (data.mediaWidth == "60" ? styles.image60 : "") + (data.mediaWidth == "40" ? styles.image40 : "")}>
        
        {data.sectionBody != null &&
              <div dangerouslySetInnerHTML={{
              __html: data.sectionBody.childMarkdownRemark.html
            }}></div>
        }
        
        {data.image != null &&
            <Img
              alt={data.image.title}
              fluid={data.image.fluid}
              fadeIn={false}
              loading="lazy"
              imgStyle={{"objectPosition": "right center"}}
              // style={{height: "100%", width: "100%", position: "absolute", top: 0}} 
              />
        }

        {/* {data.image == null &&
          <div class={styles.contactBtn}>
            <a href="/contact">Get in touch</a>
          </div>
        } */}
      </section>
    );
  }
}
