import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroSlide from '../components/hero-slide'
import PageSection from '../components/sections/page-section'
import BadgeSection from '../components/sections/badge-section'
import ListSection from '../components/sections/list-section'
import FeaturedCasesSection from '../components/sections/featuredCases-section'

class RootIndex extends React.Component {
  render() {
    const page = get(this, 'props.data.page');
    const url = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <Layout location={this.props.location}>
          <SEO startPage></SEO>
          <HeroSlide webm={page.topVideoWebm} h265={page.topVideoH265} mobileWebm={page.topVideoMobileWebm} mobileh265={page.topVideoMobileH265} poster={page.topVideoPoster}></HeroSlide>
          {/* <LandingSection/> */}
          
          <div className="content">
            {page.sections.map((node) => {
              if(node.sys.contentType.sys.id == "landingPageSection") {
                return(
                  <PageSection data={node}/>
                )
              }
              if(node.sys.contentType.sys.id == "headerSection") {
                return(
                  <div className={(node.rightAligned ? "rightHeader" : "")+ " " + (node.flipped ? "flippedHeader" : "")} dangerouslySetInnerHTML={{
                    __html: node.headerText.childMarkdownRemark.html
                  }}></div>
                )
              }

              if(node.sys.contentType.sys.id == "featuredCasesSection") {
                return(
                  <FeaturedCasesSection featuredCases={node.featuredCases}/>
                )
              } 

              if(node.sys.contentType.sys.id == "listSection") {
                return(
                  <ListSection data={node}/>
                )
              } 

              if(node.sys.contentType.sys.id == "badgeSection") {
                return(
                  <BadgeSection data={node}/>
                )
              }
              if(node.sys.contentType.sys.id == "videoSection") {
                return(
                  <video className={"fullScreenVideo"} autoPlay={node.autoplay} loop muted={node.autoplay} controls={!node.autoplay} playsInline={node.autoplay}>
                    <source src={node.video.file.url} type="video/mp4"/>
                  </video>
                )
              } 
            })}
          </div>
        </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }

    page: contentfulPage(name: {eq: "South North Landing"}) {
      name
      title
      subtitle
      topVideoWebm {
        title
        file {
          url
        }
      }
      topVideoH265 {
        title
        file {
          url
        }
      }
      topVideoMobileWebm {
        title
        file {
          url
        }
      }
      topVideoMobileH265 {
        title
        file {
          url
        }
      }
      topVideoPoster {
        title
        file {
          url
        }
      }
      sections {
        ... on ContentfulPageSection {
          name
          sectionBody {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            fluid (maxWidth: 1920, quality: 70, sizes: "(max-width: 480px) 480px, (max-width: 960px) 960px, 1920px"){
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mediaWidth
          mediaOrder
          sectionId
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        ... on ContentfulHeaderSection {
          headerText {
            childMarkdownRemark {
              html
            }
          }
          rightAligned
          flipped
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }

        ... on ContentfulFeaturedCasesSection {
          featuredCases {
            image {
              title
              fluid (maxWidth: 1920, quality: 70, sizes: "(max-width: 480px) 480px, (max-width: 960px) 960px, 1920px"){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            case {
              slug
            }
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        ... on ContentfulListSection {
          id
          header
          items {
            name
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }

        ... on ContentfulBadgeSection {
          inverted
          badges {
            title
            fixed (height: 96){
              ...GatsbyContentfulFixed_withWebp
            }
            file {
              url
            }
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        ... on ContentfulVideoSection {
          name
          sectionBody {
            childMarkdownRemark {
              html
            }
          }
          video {
            file {
              url
            }
          }
          youtubeId
          mediaWidth
          mediaOrder
          autoplay
          phoneFrame
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`