import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { Parallax } from "react-scroll-parallax";

function ParallaxContainer(props) {

    const {
        children,
        className,
        speed = 6
    } = props || {}

    const isDesktop = useMediaQuery({ query: `(min-width: 1024px)` });
    
    return (
        <Parallax className={className} disabled={!isDesktop} speed={speed}>
            {children}
        </Parallax>
    )
}

export default ParallaxContainer