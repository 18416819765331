import React, {useState} from 'react'
import styles from './listsectionline.module.css'

const ListSectionLine = ({onClick, title, description, selected}) => {

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);

  return (
    <li onClick={() => onClick()} className={styles.line + ' ' + (selected ? styles.selected : ' ')} onMouseMove={(e) => {setMouseX(e.clientX); setMouseY(e.clientY); setInnerWidth(window.innerWidth); setInnerHeight(window.innerHeight);}}>
      <h4>{title}</h4>
      <div style={
        {
            top: (mouseY < innerHeight - 372) ? mouseY + 20 : innerHeight - 352, 
            left: (mouseX < innerWidth - 620) ? mouseX + 20 : innerWidth - 600
        }
        } className={styles.description} dangerouslySetInnerHTML={{
        __html: description.childMarkdownRemark.html
      }}></div>
    </li>
  );
}

export default ListSectionLine