import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './heroslide.module.css'

function HeroSlide({webm,h265,mobileWebm,mobileh265,poster}) {
  const breakpoints = useBreakpoint();
  
  return (
    <div className={styles.topSlide}>

      {breakpoints.sm ? 
        <video poster={poster.file.url} loop="true" controls="true" playsInline="true" preload="none">
          {mobileWebm != null &&
            <source type="video/webm" src={mobileWebm.file.url} />
          }
          {mobileh265 != null &&
            <source type="video/mp4" src={mobileh265.file.url} />
          }
        </video>
      :
        <video poster={poster.file.url} autoPlay="true" muted="true" loop="true" playsInline="true">
          {webm != null &&
            <source type="video/webm" src={webm.file.url} />
          }
          {h265 != null &&
            <source type="video/mp4" src={h265.file.url} />
          }
        </video>
      }
    </div>
  )
}

export default HeroSlide