import React, { PureComponent } from 'react'
import Img from 'gatsby-image'
import styles from './badgesection.module.css'


export default class BadgeSection extends PureComponent {
  render() {
    const data = this.props.data;

    return(
      <section 
        id={data.sectionId} 
        className={styles.badgeSection + " " + (data.inverted ? styles.inverted : "")}>
          {data.badges.map((node) => {
            return(
              <div className={styles.badge}>
                <Img fixed={node.fixed} alt={node.title} imgStyle={{ height: '4em', width: '100%', 'object-fit': 'contain' }}/>
              </div>
              )
          })}
      </section>
    );
  }
}
