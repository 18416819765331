import React, {useState} from 'react'
import styles from './listsection.module.css'
import ListSectionLine from './listsection-line'

const ListSection = ({data}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleClick(index) {
    setSelectedIndex(index);
    console.log(index);
  }

  return(
    <div className={styles.listsection}>
      <h2>{data.header}</h2>
      <ul>
        {data.items.map((item,index) => {
          return(
            <ListSectionLine onClick={() => handleClick(index)} title={item.name} description={item.description} selected={index == selectedIndex}/>
          )
          })
        }
      </ul>
    </div>
  );
}

export default ListSection
